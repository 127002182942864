import React from 'react'

import styled from 'styled-components'

export default () =>
  <StyledFooter>
    &#x24B8; Borys Strzelewicz
  </StyledFooter>

const StyledFooter = styled.footer`
  height: 60px;
  background: ${({ theme }) => theme.colors.gradient};
  box-shadow: 0 -3px 4px rgba(0, 0, 0, .12), 0 -4px 5px rgba(0, 0, 0, .24);
  line-height: 60px;
  text-align: center;
`