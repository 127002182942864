import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { Wave375Bot, Wave375Top } from '../../../svgs/Waves'

import Contact from './Contact'

export default () => <StaticQuery 
  query={graphql`
    {
      allContentfulHero {
        edges {
          node {
            image {
              fluid(maxWidth:230) {
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  `}
  render={({ allContentfulHero: { edges: [ { node: { image: { fluid: {  src, srcSet, sizes }}}}]}}) =>
    <StyledSection>
      <WavyContainer>
        <StyledTitle>
          Borys Strzelewicz
          <br/>
          Front-end Developer
        </StyledTitle>
        <StyledWave375Top/>
      </WavyContainer>
      <StyledSubcontainer>
        <Contact />
        <div style={{ overflow: 'hidden' }}>
          <StyledImage src={src} srcSet={srcSet} sizes={sizes} />
        </div>
      </StyledSubcontainer>
      <StyledWave375Bot />
    </StyledSection>
  }
/>

const StyledImage = styled.img`
  display: none;

  @media(min-width: 1366px) {
    display: block;
    width: auto;
    align-self: center;
    position: absolute;
    bottom: 0;
    z-index: 1;
  }
`

const StyledSection = styled.section`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
  background: ${({ theme }) => theme.colors.gradient};
`

const WavyContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  padding: ${({ theme }) => theme.baseDistance * 2}px;
  padding-top: ${({ theme }) => theme.baseDistance * 10}px;

  @media(min-width: 1366px) {
    padding-top: ${({ theme }) => theme.baseDistance * 8 + 60}px;
  }
`

const StyledSubcontainer = styled.div`
  padding: ${({ theme }) => theme.baseDistance * 10}px ${({ theme }) => theme.baseDistance * 2}px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media(min-width: 1366px) {
    padding: ${({ theme }) => theme.baseDistance * 4}px;
  }
`

const StyledTitle = styled.h2`
  text-align: center;
  margin: 0;
  width: 100%;

  @media(max-width: 1365px) {
    font-size: ${({ theme }) => theme.baseDistance * 4}px;
    line-height: ${({ theme }) => theme.baseDistance * 5}px;
  }

  @media(max-width: 330px) {
    font-size: ${({ theme }) => theme.baseDistance * 3.5}px;
  }
`

const StyledWave375Top = styled(Wave375Top)`
  position: absolute;
  width: 100%;
  top: 160px;
  left: 0;
  height: 60px;

  @media(min-width: 1366px) {
    height: 80px;
    top: 275px;
  }
`

const StyledWave375Bot = styled(Wave375Bot)`
  position: relative;
  width: 100%;
  height: 60px;
  bottom: -1px;
  left: 0;
  z-index: 2;

  @media(min-width: 1366px) {
    height: 80px;
  }
`