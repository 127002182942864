import React from 'react'

import styled, { ThemeProvider } from 'styled-components'

import theme from '../utils/themes'
import GlobalStyle from '../utils/GlobalStyle'
import SEO from '../components/seo'

import Header from '../components/general/Header'
import Footer from '../components/general/Footer'

export default ({ children }) => 
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      <SEO />
      <Header />
      <StyledMain>
        {children}
      </StyledMain>
      <Footer />
    </>
  </ThemeProvider>

const StyledMain = styled.main`
  /* background: ${({ theme }) => theme.colors.darkBlue}; */
  width: 100%;
`