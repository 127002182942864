import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import styled from 'styled-components'

import AboutItem from './AboutItem'

export default () => <StaticQuery 
  query={graphql`
    {
      allContentfulAboutItem {
        edges {
          node {
            title
            description {
              description
            }
            icon {
              file {
                url
              }
            }
          }
        }
      }
    }
  `}
  render={({ allContentfulAboutItem: { edges: aboutItems }}) => 
    <StyledSection>
      <StyledH2>About me</StyledH2>
      <StyledContainer>
        {aboutItems.reverse().map(({ node }) => <AboutItem data={node} />)}
      </StyledContainer>
    </StyledSection>
  }
/>

const StyledSection = styled.section`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  padding: ${({ theme }) => theme.baseDistance * 6}px ${({ theme }) => theme.baseDistance * 2}px;

  @media(min-width: 375px) {
    padding: ${({ theme }) => theme.baseDistance * 4}px;
  }
`

const StyledContainer = styled.div`
  display: grid;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.baseDistance * 2};
  justify-items: center;
  grid-gap: 64px;
  grid-template-columns: 100%;

  @media(min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media(min-width: 1366px) {
    max-width: 1366px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
`

const StyledH2 = styled.h2`
  text-shadow: ${({ theme }) => theme['375'].fontSizes.medium.shadow};
  margin: 0;
  text-align: center;

  @media(max-width: 374px) {
    font-size: ${({ theme }) => theme['375'].fontSizes.medium.fontSize};
    line-height: ${({ theme }) => theme['375'].fontSizes.medium.lineHeight};
  }

  @media(min-width: 375px) {
    margin-bottom: 64px;
  }
`