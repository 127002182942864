import React from 'react'

import styled from 'styled-components'

export default ({ data: { icon, title, description }}) => 
  <StyledContainer>
    <StyledCircle>
      <StyledIcon src={icon.file.url} alt="" />
    </StyledCircle>
    <StyledTitle>{title}</StyledTitle>
    <StyledParagraph  >
      {description.description}
    </StyledParagraph>
  </StyledContainer>

const StyledContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  justify-items: center;
  text-align: center;
  grid-gap: 8px;
`

const StyledParagraph = styled.p`
  margin: 0;
  max-width: 100%;

  & p {
    margin: 0;
  }
`

const StyledTitle = styled.h3`
  text-shadow: ${({ theme }) => theme['375'].fontSizes.small.shadow};
  margin: 0;
`

const StyledCircle = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.teal};
  box-shadow: 0 0 7px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .24);
  display: flex;
  justify-content: center;
  align-items: center;

  @media(min-width: 900px) {
    width: 150px;
    height: 150px;
  }
`

const StyledIcon = styled.img`
  width: 60px;
  height: 60px;

  @media(min-width: 900px) {
    width: 100px;
    height: 100px;
  }
`